import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import TypeToBlock from "../components/type-to-block";

export const query = graphql`
  query ($languageISO: String!, $language: String!, $slug: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    stripePlanMonthly: stripePlans(interval: { eq: "month" }) {
      id
      currency
      amount
      interval
      interval_count
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
    plan: contentfulPlanModule(
      planSlug: { eq: $slug }
      node_locale: { eq: $languageISO }
    ) {
      id
      planSlug
      planName
      shortDescription
      metaTitle
      metaDescription
      modules {
        id
        heading
        subheading
        type
        name
        items {
          ... on ContentfulPlanModule {
            id
            planSlug
            planName
            shortDescription
            featureImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            featureImageMobile {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          ... on ContentfulItems {
            id
            body
            heading
            subheading
            link
            branchLink
            linkHelpText
            linkText
            video
            videoThumbnail {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            desktopImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            mobileImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            backgroundImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            backgroundImageMobile {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            content {
              raw
            }
          }
        }
      }
    }
  }
`;

const Plan = ({
  data: {
    plan: { metaTitle, metaDescription, modules, planName },
    stripePlanMonthly,
    ribbon,
    header,
    footer,
  },
}) => {
  const { t } = useTranslation();
  // For removing default connected entries.
  // TODO: Find a way to improve this logic.
  const uniqueModules = modules.slice(0, -2);
  let localePageAvailable = metaTitle || planName ? true : false;
  // uniqueModules.forEach((module, index) => {
  //   if (module.type === "Full page horizontal carousel" && !module.items[0].heading) {
  //     localePageAvailable = false;
  //   }
  // });

  const filtereduniqueModules = uniqueModules.filter(
    (module) => module.name !== "Featured Plans Carousel home."
  );

  let hasBanner;
  if (filtereduniqueModules[0].type !== "Full page horizontal carousel") {
    hasBanner = "no-banner";
  }

  return (
    <>
      <Layout
        header={header}
        ribbon={ribbon}
        footer={footer}
        hasBanner={hasBanner}
      >
        <Seo title={metaTitle} metaDescription={metaDescription} />
        {localePageAvailable &&
          filtereduniqueModules.length > 0 &&
          filtereduniqueModules.map(
            (module, key) =>
              module.type !== "Plan List Block" && (
                <TypeToBlock
                  key={`module-${key}`}
                  stripePlanMonthly={stripePlanMonthly}
                  module={module}
                />
              )
          )}
        {!localePageAvailable && (
          <section className="error_page_section">
            <div className="container">
              <h4>{t("contentMissing")}</h4>
              <h5>{t("contentMissingMessage")}</h5>
            </div>
          </section>
        )}
      </Layout>
    </>
  );
};

export default Plan;
